import React from 'react';
import styles from './DashboardFooter.module.css';

const DashboardFooter = () => {
  return (
    <footer className={styles.dashboardFooter}>
      © Copyright 2024. TalenTrail
    </footer>
  );
};

export default DashboardFooter;
